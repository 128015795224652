import type { FieldProps } from '@pretto/zen/reveal/types/Field'

import { searchPlaces } from '@pretto/places'
import { MunicipalitySearchResult } from '@pretto/places/dist/types'

import { useEffect, useState } from 'react'

export type Result = MunicipalitySearchResult

type UseCityField = (defaultValue?: string) => {
  fieldProps: FieldProps
  results: Result[]
}

export const useCityField: UseCityField = defaultValue => {
  const [searchValue, setSearchValue] = useState(defaultValue ?? '')
  const [results, setResults] = useState<Result[]>([])

  useEffect(() => {
    ;(async () => {
      if (searchValue === '') {
        setResults([])
        return
      }

      const results = await searchPlaces(searchValue, { limit: 50 })
      setResults(results)
    })()
  }, [searchValue])

  const handleChange = (value: string) => {
    setSearchValue(value)
  }

  return {
    fieldProps: {
      onChange: handleChange,
      value: searchValue,
    },
    results,
  }
}
