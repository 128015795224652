import { useLoading } from '@pretto/app-core/loading/lib/useLoading'

import { gql, useApolloClient, useMutation } from '@apollo/client'
import { nanoid } from 'nanoid'
import PropTypes from 'prop-types'
import { createContext, useContext, useEffect, useState } from 'react'
import { Route, Switch, useHistory, useLocation } from 'react-router-dom'

import * as queries from '../../../apollo'
import { EMPTY_FUNCTION } from '../../../lib/constants'
import { invalidateTracking, trackAction } from '../../../lib/tracking'
import { useNewsletter } from '../../../services/newsletter'
import * as helpers from '../../lib/helpers'
import ImpersonatePage from '../ImpersonatePage'

export class ErrorEmailNotFound extends Error {}

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const { query, resetStore } = useApolloClient()
  const { pathname } = useLocation()
  const history = useHistory()
  const [assign] = useMutation(queries.ASSIGN_PROJECT)
  const [subscribeRate] = useMutation(queries.SUBSCRIBE_RATE_ALERT)

  const { subscribe } = useNewsletter()

  const [isLoading, setIsLoading] = useState(false)
  const [isLoggedIn, setIsLoggedIn] = useState(helpers.isLoggedIn())

  useLoading(isLoading)

  useEffect(() => {
    const accessToken = helpers.getAuthToken()
    const refreshToken = helpers.getRefreshToken()

    if (!isLoggedIn && accessToken) {
      signIn({ accessToken, refreshToken })
    }
  }, [pathname])

  const refetch = async () => {
    setIsLoading(true)

    await resetStore()

    setIsLoading(false)
  }

  const subscribeNewsletterImmo = async () => {
    const isSubscribed = await subscribe()

    if (isSubscribed) {
      trackAction('NEWSLETTER_SUBSCRIBED', { newsletter_subscribe_source: 'signup', newsletter_subscribe_type: 'immo' })
    }
  }

  const subscribeNewsletterRate = async () => {
    const response = await subscribeRate()
    const { error } = response.data.subscribe_rate_alert
    if (!error)
      trackAction('NEWSLETTER_SUBSCRIBED', { newsletter_subscribe_source: 'signup', newsletter_subscribe_type: 'rate' })
  }

  const handleSignIn = async (data, params) => {
    const { projectID, subscribeNewsletter, subscribeRateAlert, redirectUri } = params

    const { access_token: accessToken, refresh_token: refreshToken, is_new_account: isNewAccount } = data

    const redirect = () => {
      if (!redirectUri) {
        return
      }

      history.push(redirectUri)
    }

    if (!isNewAccount) {
      await signIn({ accessToken, refreshToken }, redirect)
    } else {
      const assignProject = async () => {
        try {
          await assign({ variables: { id: projectID } })
        } catch (error) {
          return EMPTY_FUNCTION
        }
      }

      const asyncFunctions = [
        assignProject,
        subscribeNewsletter && subscribeNewsletterImmo,
        subscribeRateAlert && subscribeNewsletterRate,
      ]

      await signIn({ accessToken, refreshToken }, redirect, asyncFunctions)
    }
  }

  const requestGoogleConnect = async params => {
    const state = nanoid()
    const data = await helpers.authorize(state)

    await handleSignIn(data, params)
  }

  const requestSignIn = async (email, { redirectUri }) => {
    const { data } = await query({
      fetchPolicy: 'network-only',
      query: gql`
        query RequestSignIn($email: String!, $redirectUri: String!) {
          requestSignIn: passwordless_start(email: $email, template: "espace_client", redirect_uri: $redirectUri) {
            error
          }
        }
      `,
      variables: {
        email,
        redirectUri,
      },
    })

    switch (data.requestSignIn.error) {
      case 'email_not_found':
        throw new ErrorEmailNotFound()

      default:
    }
  }

  const signIn = async (tokens, redirect = EMPTY_FUNCTION, asyncCallBacks = [], isLoadingEnabled = true) => {
    if (isLoadingEnabled) {
      setIsLoading(true)
    }

    if (isLoggedIn) {
      await signOut({ ignoreLoading: true, ignoreRedirect: true })
    }

    trackAction('LOGGED_IN')

    helpers.persistTokens(tokens)

    // In React, two subsequent calls of a state setter that cancel each other, eg. setIsLoading(true)
    // followed by setIsLoading(false), will not trigger a re-render. To prevent this behavior,
    // you may just wait for an empty promise to complete in between those two calls.
    await Promise.resolve()

    if (asyncCallBacks.length > 0) {
      for (const callback of asyncCallBacks) {
        if (callback) await callback()
      }
    }

    await redirect()

    setIsLoggedIn(true)
    setIsLoading(false)
  }

  const signOut = async ({ redirect = '/login', ignoreLoading = false, ignoreRedirect = false } = {}) => {
    if (!ignoreLoading) {
      setIsLoading(true)
    }

    trackAction('LOGGED_OUT')

    await resetStore()
    await helpers.flushTokens()

    invalidateTracking()

    if (!ignoreRedirect) {
      window.location.href = `${process.env.API_PRETTO_HOST}/auth/logout?redirect=${encodeURIComponent(
        redirect ?? '/login'
      )}`
    } else {
      try {
        await fetch(`${process.env.API_PRETTO_HOST}/auth/logout`)
      } catch (error) {}
    }
  }

  if (isLoading) {
    return null
  }

  const userSource = helpers.getUserProfile()
  const isImpersonated = userSource && userSource === 'internal'

  const value = {
    handleSignIn,
    isImpersonated,
    isLoggedIn,

    refetch,

    requestGoogleConnect,
    requestSignIn,

    signIn,
    signOut,
  }

  return (
    <AuthContext.Provider value={value}>
      <Switch>
        <Route exact path="/impersonate" component={ImpersonatePage} />
        <Route render={() => children} />
      </Switch>
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

export const useAuth = () => useContext(AuthContext)

export default AuthProvider
